import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Center from '../components/styles/Center';
import Page from '../components/Page';

const fourOhFourPage = () => (
  <Page>
    <Center>
      <Helmet title="Not Found" />
      <h1>Page Not Found</h1>
      <Link to="/">
        <h2>Home</h2>
      </Link>
    </Center>
  </Page>
);
export default fourOhFourPage;
